import React, { useState, useEffect } from 'react';
import { data } from './Card';
import image from '../asset/image.png';
import Rectangle8 from '../asset/Rectangle8.png';
import Rectangle9 from '../asset/Rectangle9.png';
import Check from '../asset/Check.png';
import PersonIcon from '@mui/icons-material/Person';


 import addservice from "../asset/addservice.svg";
import { useNavigate } from 'react-router-dom';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';


const Header = () => {
  const [userData, setUserData] = useState(null);
const navigate=useNavigate();
const { t, i18n } = useTranslation();
  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem('userData');
    console.log('name', storedUserData);
    if (storedUserData) {
      // Parse the JSON string into a JavaScript object
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);
   const handleAddService = () => {
    navigate('/dashboard/homeservices'); 
  
  };
  const handleAddProperty = () => {
    const userData = localStorage.getItem('userData');
    const token = userData ? JSON.parse(userData).access_token : null;
  
    if (token) {
      // If token exists, navigate to Add Property page
      navigate('/dashboard/propertylisting');
    } else {
      // If token does not exist, navigate to Login page
      navigate('/login');
    }
  
 
  };

  

  const name = userData ? userData.name : '';
  return (
    <div className="">
      <div className="flex justify-end items-center bg-[#D9D9D9] p-3">
      <div className='flex space-x-4 px-7'>
        <div className='flex'>
          <button onClick={handleAddService} className="bg-yellow-500 py-2 px-4 flex w-[140px]  rounded-[20px] text-[#2E4374] text-[14px]">{t('Add Service')} 
          <img  src={addservice} alt="gghged" className='pl-3'/>
          
            </button>
           
        </div>
        <div>
         
          <button onClick={handleAddProperty} className="flex items-center border text-[14px] bg-[#A44531] border-[#A44531] rounded-[20px] text-[#DDC252]  py-2 px-4">
            {t('Add Property')}   <span className='bg-white ml-2 px-2 text-[14px] rounded-full'> <ArrowOutwardIcon  /> {/* Arrow Icon */}
          {t('Business')}  </span>
        </button>
        </div>
      </div>
      
      <div className="flex items-center">
        <PersonIcon className="mr-2" />
        <p  className={` ${i18n.language === 'ckb' ? 'ml-6 capitalize' : 'pr-6 capitalize'}`}>{name}</p>
      </div>
    </div>

      <div className="mx-[40px]  mt-4">
        <h1 className="text-[20px] text-black font-[400] capitalize thirdCustom">   {t('Hello Welcome Back')} !! </h1>
      </div>

      <div className="mx-[40px] mt-[30px]">
        <div className="">
          {data.map((value, index) => (
            <div className="flex flex-row gap-3" key={index}>
              <div className= {`${i18n.language === 'ckb' ? 'w-[70%] bg-[#EAF7FF]  py-16 pr-4 flex justify-between ' : 'w-[70%] bg-[#EAF7FF]  py-16 flex justify-between '}`}>
                <div className="h-auto ml-10">
                  <h1 className="text-emerald-400 my-2 uppercase leading-[20px]">{value.title}</h1>
                  <p className="my-2 text-[22px]  font-[400] capitalize thirdCustom">
                    {value.description} <br />
                    <span className="text-[#1E2BE2] thirdCustom">${value.price}</span> Rent!{' '}
                  </p>
                  <p className="my-2 text-[15px] capitalize text-slate-500  leadong-[24px] thirdCustom">{value.para}</p>
                  <div className="flex">
                    <button className="bg-[#7C8CED] text-[16px] my-4 w-[130px] rounded-[40px] text-white p-2">
                      Book Now
                    </button>
                    <p className="mt-5 ml-4 text-slate-600  text-[18px] font-[500] thirdCustom">
                      Current Rate <span className="ml-2 text-slate-500 font-[400] thirdCustom">${value.price}</span>{' '}
                    </p>
                  </div>
                </div>
                <div className="mt-12 mr-10">
                  <img  src={value.img} className="w-[220px] h-auto" alt="dynamic" />
                </div>
              </div>







              <div
                className="w-[35%]  border-l-0 border-r-0   relative bg-cover bg-center h-[400px]"
                style={{ backgroundImage: `url(${image})` }}
                id="brd"
              >
                <div className="absolute top-3 left-3 z-[2] m-6">
                  <img src={Rectangle8} className="re" alt="dynamic" />
                  <div className="absolute top-0 ">
                    <h1 className="text-white text-[24px] text-left pl-5 font-[400] thirdCustom">
                      Home <br />
                      Services
                    </h1>
                    <div className="flex gap-2 mx-6 my-3">
                      <span className="relative top-[2px] text-[16px] text-white">100% </span>
                      <p className="text-[11px] text-left  text-white text-nowrap thirdCustom">
                        Guranted fast and <br /> reliable services
                      </p>
                    </div>

                    <h2 className="text-[16px] text-center  mt-1 text-[#FAD13B] thirdCustom ">
                      "Bring care to your doorstep"
                    </h2>
                  </div>
                </div>

                <div className="absolute  top-4 left-32 z-[1]">
                  <img  src={Rectangle9} className="3xl:w-[540px] h-[220px]" alt="dynamic" />
                </div>

                <div className="relative top-[230px] flex flex-col md:flex-row justify-around mx-4 h-auto md:gap-10 gap-4">
                  <div className="flex flex-col pt-4 items-center md:items-start">
                    <button className="w-[110px] bg-[#000000] text-white rounded-[10px] mt-2 p-1 text-[14px] font-[400] thirdCustom">
                      Contact us
                    </button>
                    <a className="text-black text-[14px] font-[500] my-1 thirdCustom" href="/">
                      www.squarebric.com
                    </a>
                  </div>

                  <div className="mt-2 w-full md:w-auto lg:w-auto flex flex-col">
                    <h1 className="border-b-2 border-yellow-500 text-center md:text-left">Our services</h1>
                    <ul className="grid grid-cols-2  gap-3 list-disc list-inside">
                      <li
                        className="list-item text-[10px] font-[400] font-family-['segoe']"
                        style={{ listStyleImage: `url(${Check})` }}
                      >
                        cleaning
                      </li>
                      <li
                        className="list-item text-[10px] font-[400] thirdCustom"
                        style={{ listStyleImage: `url(${Check})` }}
                      >
                        painting
                      </li>
                      <li
                        className="list-item text-[10px] font-[400] thirdCustom"
                        style={{ listStyleImage: `url(${Check})` }}
                      >
                        electrical
                      </li>
                      <li
                        className="list-item text-[10px] font-[400] thirdCustom"
                        style={{ listStyleImage: `url(${Check})` }}
                      >
                        Dry wall
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Header;
