import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from 'react-router-dom';
import './Property.css';

const Allservices = () => {
  const [apiData, setApiData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeArrow, setActiveArrow] = useState(null); // New state for tracking active arrow button
  const isSmallScreen = useMediaQuery('(max-width:630px)');
  const isTabletScreen = useMediaQuery('(max-width:1050px)');
  const isLargeScreen = useMediaQuery('(min-width:1051px)');
  const isExtraLargeScreen = useMediaQuery('(min-width:1451px)');

  const serviceImages = [
    'https://squarebric-media-files.squarebric.com/Furniture4.png',
    'https://squarebric-media-files.squarebric.com/Swiming_pool.png',
    'https://squarebric-media-files.squarebric.com/Moving.png',
    'https://squarebric-media-files.squarebric.com/Paint.png',
    'https://squarebric-media-files.squarebric.com/Cleaning1.jpg',
    'https://squarebric-media-files.squarebric.com/Architecture2.jpg',
    'https://squarebric-media-files.squarebric.com/Security.jpg',
    'https://squarebric-media-files.squarebric.com/Kitchen2.jpg'
  ];

  let numberOfCardsToShow = 8;

  if (isSmallScreen) {
    numberOfCardsToShow = 1;
  } else if (isTabletScreen) {
    numberOfCardsToShow = 2;
  } 
  // else if (isLargeScreen) {
  //   numberOfCardsToShow = 2;
  // } else if (isExtraLargeScreen) {
  //   numberOfCardsToShow = 8;
  // }

  const totalCards = serviceImages.length;
  const hasNextSlide = currentIndex < totalCards - numberOfCardsToShow;
  const hasPrevSlide = currentIndex > 0;

  const nextSlide = () => {
    if (hasNextSlide) {
      setCurrentIndex((prevIndex) => prevIndex + numberOfCardsToShow);
      setActiveArrow('next'); // Set active arrow to next
    }
  };

  const prevSlide = () => {
    if (hasPrevSlide) {
      setCurrentIndex((prevIndex) => prevIndex - numberOfCardsToShow);
      setActiveArrow('prev'); // Set active arrow to prev
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://square-bric-backend-api.squarebric.com/home-service/home-service-listing-count'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        setApiData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const serviceNames = [
    'Furniture Cleaning',
    'Swimming Pool Cleaning',
    'Movers & Packers',
    'Painting Service',
    'Deep Cleaning',
    'Architecture',
    'Security System',
    'Kitchen Cleaning'
  ];

  const visibleImages = serviceImages.slice(currentIndex, currentIndex + numberOfCardsToShow);

  return (
    <>
      <div className="md:mt-32 mt-16 text-[#2E4374]  max-w-7xl mx-auto">
        <div
          
        >
    
            <h1
              className={`text-2xl mr-4 md:mr-0 font-fourth text-[#2E4374]`}
             
    
            >
              Explore Our Expert Services
            </h1>



          <div className="relative ">
          <div
              className="grid gap-4 p-8 md:p-0 md:mt-14 mt-0"
              style={{
                gridTemplateColumns: isSmallScreen
                  ? 'repeat(1, 1fr)'
                  : isTabletScreen
                  ? 'repeat(2, 1fr)'
                  : 'repeat(4, 1fr)',
                gridAutoRows: isLargeScreen ? 'minmax(100px, auto)' : 'auto',
                gridAutoFlow: 'row dense'
              }}
            >
        
              {visibleImages.map((image, index) => (
                <div
                  key={index}
                  className="relative shadow rounded-xl"
                  style={{ transition: 'transform 0.3s ease' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.05)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                >
                  <div style={{ paddingTop: '85%', position: 'relative' }}>
                    <img
                      src={image}
                      alt={`Service Image ${currentIndex + index + 1}`}
                      className="absolute inset-0 w-full h-full rounded-t-sm object-cover"
                    />
                  </div>
                  <div className="p-2 bg-[#EAF4FA] rounded-b-xl flex ">
                    <div>
                      <Link to="/product">
                        <p
                          className={`text-[#2E4374] font-SecondCustom transition-colors duration-300 hover:text-gray-600 ${
                            isSmallScreen ? 'text-center' : ''
                          }`}
                          style={{ fontSize: isSmallScreen ? '15px' : '15px' }}
                        >
                          {serviceNames[currentIndex + index]}
                        </p>
                        <p
                          className={`text-[#FFA500] font-thirdCustom md:text-sm transition-colors duration-300 hover:text-gray-600 ${
                            isSmallScreen ? '' : ''
                          }`}
                          style={{ fontSize: isSmallScreen ? '15px' : '15px' }}
                        >
                          Total Listing{' '}
                          {apiData.find((service) => service._id === serviceNames[currentIndex + index])?.count || 0}
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>



            {!isLargeScreen && (
              <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-between px-0">
                <button
                  className={`text-[#2e4374] p-2 shadow-lg ${!hasPrevSlide ? 'opacity-50 pointer-events-none ' : ''} ${
                    activeArrow === 'prev' ? 'bg-[#2E4374] text-white opacity-60' : 'bg-white opacity-60'
                  }`}
                  onClick={prevSlide}
                >
                  <ArrowBackIosNewIcon />
                </button>
                <button
                  className={`text-[#2e4374] p-2 shadow-lg ${!hasNextSlide ? 'opacity-50 pointer-events-none' : ''} ${
                    activeArrow === 'next' ? 'bg-[#2E4374] text-white opacity-60' : 'bg-white opacity-60'
                  }`}
                  onClick={nextSlide}
                >
                  <ArrowForwardIosIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>{/* <PropertyManager /> */}</div>
    </>
  );
};

export default Allservices;
