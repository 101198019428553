import React, { useState } from 'react';
import { lifestyledata, featureimagedata } from './lifestyledata';
import { Card, CardContent, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './featured.css';
import { useTranslation } from 'react-i18next';

const Lifestyle = () => {
  const [displayedCards, setDisplayedCards] = useState(8); // Initially show 3 cards
  const allLifestyleData = [...lifestyledata, ...lifestyledata, ...lifestyledata]; // Assuming you have more data
  const { t, i18n } = useTranslation();
  const handleClickViewMore = () => {
    const newDisplayedCards = displayedCards + 3;
    setDisplayedCards(newDisplayedCards > allLifestyleData.length ? allLifestyleData.length : newDisplayedCards);
  };
  return (
    <div className="mt-14 ">
      <div>
        <h1 className="font-SecondCustom md:text-4xl text-2xl flex justify-center items-center md:ml-0 xs:ml-14 ml-6 text-[#1E2858] m-1 ">
         
          {t('Experience A Luxurious Lifestyle')}
        </h1>
      </div>

      <div className=" grid 3xl:gap-5 2xl:gap-5 xl:gap-18 lg:gap-28 md:gap-5 gap-5 grid-cols-1 lg:grid-cols-2 w-3/4 mt-20 m-1 justify-start   max-w-7xl mx-auto">
        {allLifestyleData.slice(0, displayedCards).map((lifestyle, i) => (
          <div key={i} className="flex  justify-start flex-col ">
            {/* {featureimagedata.map((image, i) => (
                <img src={image.image} className="" />
            ))} */}
            <div className=" ">
              <img src={lifestyle.image} alt="" className="residenceimage" />
            </div>

            <Card className="-mt-16 md:ml-20 ml-4 mobileCard">
              <div className="cardcontent">
                <CardContent>
                  <h1 className=" font-SecondCustom md:text-2xl text-xl font-bold text-[#2E4374] mt-1">
                    {lifestyle.title}
                  </h1>
                  <p className="font-thirdCustom text-[#1E2858] md:text-base text-sm mt-2">{lifestyle.description}</p>

                  <div className="flex">
                    <span className="mt-2 flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.42284 4.31938C2.1483 7.59386 2.14821 12.9029 5.42263 16.1775L9.37672 20.1306C10.4683 21.2211 12.2364 21.2211 13.3277 20.1309L17.2811 16.1775C20.5555 12.9029 20.5554 7.59386 17.2809 4.31938C14.0064 1.04491 8.69737 1.04491 5.42284 4.31938ZM15.9633 5.63713C18.5102 8.18394 18.5103 12.3132 15.9635 14.8601L12.0105 18.8131C11.647 19.1762 11.0576 19.1762 10.6939 18.8128L6.74015 14.86C4.19347 12.3132 4.19354 8.18394 6.7404 5.63713C9.28726 3.09031 13.4165 3.09031 15.9633 5.63713ZM11.3519 6.5216C9.29369 6.5216 7.62521 8.19008 7.62521 10.2483C7.62521 12.3064 9.29369 13.9749 11.3519 13.9749C13.4101 13.9749 15.0785 12.3064 15.0785 10.2483C15.0785 8.19008 13.4101 6.5216 11.3519 6.5216ZM11.3519 8.38511C12.381 8.38511 13.2152 9.21935 13.2152 10.2484C13.2152 11.2775 12.381 12.1118 11.3519 12.1118C10.3228 12.1118 9.48854 11.2775 9.48854 10.2484C9.48854 9.21935 10.3228 8.38511 11.3519 8.38511Z"
                          fill="url(#paint0_linear_5271_4832)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_5271_4832"
                            x1="33.5224"
                            y1="-28.3543"
                            x2="-22.0388"
                            y2="-12.5881"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#071C35" />
                            <stop offset="1" stop-color="#5B8BC5" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <span className="xs:mt-1 md:-mt-0.5 md:ml-2 text-[#1E2858] font-thirdCustom md:text-base text-xs font-semibold">
                      {lifestyle.location}
                    </span>
                    </span>
              
                  </div>
                  <div className="grid-container mt-4">
                    <div className="info-block">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        style={{ display: 'flex', fontSize: '1rem' }}
                      >
                        <img src="https://squarebric-media-files.squarebric.com/Vector.svg" alt="tag" className="" />
                        <div className="flex-col flex">
                          <span className="text-xs ml-2 text-[#818EAE]">Super Area</span>
                          <span className="text-xs ml-2 text-[#2E4374]">402 sq.ft</span>
                        </div>
                      </Typography>
                    </div>

                    <div className="info-block">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        style={{ display: 'flex', fontSize: '1rem' }}
                      >
                        <img src="https://squarebric-media-files.squarebric.com/Style.svg" alt="tag" className="" />
                        <div className="flex-col flex">
                          <span className="text-xs ml-2 text-[#818EAE]">Status</span>
                          <span className="text-xs ml-2 text-[#2E4374]">Ready to move</span>
                        </div>
                      </Typography>
                    </div>

                    <div className="info-block">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        style={{ display: 'flex', fontSize: '1rem' }}
                      >
                        <img src="https://squarebric-media-files.squarebric.com/MGBED.svg" alt="bed" className="" />
                        <div className="flex-col flex">
                          <span className="text-xs ml-2 text-[#818EAE]">Bedroom</span>
                          <span className="text-xs ml-2 text-[#2E4374]">3</span>
                        </div>
                      </Typography>
                    </div>

                    <div className="info-block">
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="div"
                        style={{ display: 'flex', fontSize: '1rem' }}
                      >
                        <img src="https://squarebric-media-files.squarebric.com/MGBED2.svg" alt="bath" className="" />
                        <div className="flex-col flex">
                          <span className="text-xs ml-2 text-[#818EAE]">Bathroom</span>
                          <span className="text-xs ml-2 text-[#2E4374]">3</span>
                        </div>
                      </Typography>
                    </div>
                  </div>

                  <h1 className="uppercase text-[#5B8BC5] font-bold font-SecondCustom md:text-base text-sm mt-1"> Starting from</h1>

                  <div className="font-customFont md:text-2xl text-lg font-semibold text-[#1E2858] mt-3 flex justify-between">
                    <div>$ <span className='text-xl'>{lifestyle.prize}</span></div>
                  <button class="flex md:px-4 px-2  md:py-0 border-2 rounded-sm hover:text-white hover:bg-[#2e4374] border-[#2e4374] md:text-lg text-base font-thirdCustom">
                  <ArrowForwardIcon className='md:mt-2 mt-1 -ml-2' />

                      <span className="border-l-2 border-[#2e4374] hover:border-white p-1"> Read More</span>
                    </button>
                  </div>

                  {/* <div className="flex justify-end -mt-6">
                    <div className="flex border-2 rounded-sm border-[#2E4374] w-40 hover:bg-[#2e4374] hover:text-white">
                      <div className="relative inline-block mt-1 rounded-md p-2">
                        <img src={vector1} alt="tag" className="border-[#2E4374] hover:text-white" width={20} />
                      </div>
                       <ArrowForwardIcon  className='text-[#2e4374] mt-2 ml-1'/>
                      <div className="border-l-2 border-[#2E4374] ml-2"></div>
                      <div className="relative ml-2 inline-block p-1 text-lg text-[#2e4374] hover:text-white">Read More</div>
                    </div>
                  </div> */}

                  {/* <div className="flex justify-end -mt-6 "> */}

                  {/* </div> */}
                </CardContent>
              </div>
            </Card>
          </div>
        ))}
      </div>

      {displayedCards < allLifestyleData.length && (
        <div className="flex justify-center ml-10 mb-10 mt-6">
          <button
            className="border-2 bg-[#2E4374] text-white flex w-34 p-3 justify-center"
            onClick={handleClickViewMore}
          >
            <img
              src="https://squarebric-media-files.squarebric.com/downarrow.svg"
              alt="tag"
              className="border-[#2E4374] "
              width={20}
            />
            <span className="ml-2 -mt-1">View More</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Lifestyle;
