import React from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ShareIcon from '@mui/icons-material/Share';
import { useTranslation } from 'react-i18next';

const truncateAddress = (address, maxLength = 100) => {
  if (address.length > maxLength) {
    return address.slice(0, maxLength) + '...';
  }
  return address;
};
const truncatefloor = (address, maxLength = 10) => {
  if (address.length > maxLength) {
    return address.slice(0, maxLength) + '...';
  }
  return address;
};
export const FlatApartmentCard = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  SuperArea = 'N/A',
  propertyonfloor = 'N/A',
  Possessionstatus = 'N/A',
  Furnishedstatus = 'N/A',
  bathroom = 'N/A',
  bedroom = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = ' ',
  sharedBy = 0,
  cost = 'N/A',

  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  
const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg  mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-2  w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] leading-[15px] font-[700] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>

          <h2
            className="text-[#1E2858] font-[400] font-sixth  text-[12px] leading-[15px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px] ml-2">
          <div className="flex justify-start items-center">
            <img
              src="https://d34yb5orp91dp6.cloudfront.net/propertyfloor.svg"
              alt="Status"
              className="w-7 h-7 mr-2 -mt-1"
            />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Property of the floor')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{propertyonfloor}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/Possesion.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Possession Status')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Possessionstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Super Area')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{SuperArea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Bathroom" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Bathroom')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{bathroom}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Bedroom" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Bedroom')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{bedroom}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[140px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
          {t('View Details')}
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
         
            {t('Contact Agent')}
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
                {t('Enquired')}
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
            {t('Like')}
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0">
            <div
              className="flex flex-row md:flex-col md:mt-0 mt-1 cursor-pointer"
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title: 'Check this out!',
                      text: 'Have a look at this awesome content!',
                      url: window.location.href // You can customize this to the specific content you want to share
                    })
                    .then(() => console.log('Share successful'))
                    .catch((error) => console.log('Error sharing:', error));
                } else {
                  alert('Sharing is not supported on this browser.');
                }
              }}
            >
             {t('Share')}
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Share"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ResidentialHouse = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  SuperArea = 'N/A',
  propertyonfloor = 'N/A',
  bathroom = 'N/A',
  bedroom = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',

  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();
  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 md:left-6  rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-2 md:ml-0">
        <div>
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px]  uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/stairs.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Floor')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{propertyonfloor}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">  {t('Super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{SuperArea}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Bathroom')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{bathroom}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Facing')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>

          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Bedroom')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{bedroom}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center sm:items-center md:items-center lg:items-start pt-2 bg-[#BAC1D233] w-full sm:w-full md:w-[100px]  ">
        <div className="flex flex-row sm:flex-row md:flex-col justify-center sm:justify-center md:justify-center items-center sm:items-center md:items-center gap-0 sm:gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 sm:mr-6 ml-6 sm:ml-6  md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[14px] md:text-[14px] flex flex-row md:flex-col items-center sm:items-center md:items-center sm:flex-row ml-0 mr-4 sm:ml-4 sm:mr-4 -mt-2 sm:-mt-2 md:mt-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[10px] md:text-[14px] sm:mb-0 flex flex-row sm:flex-row md:flex-col items-center sm:items-start sm:justify-start md:items-center gap-0 sm:gap-0 md:gap-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full mb-2 mr-0 ">
            <div> Share</div>
            <div className="flex justify-center items-center md:items-start mb-0 sm:mb-0 md:mb-2 mr-4 sm:mr-4 md:mr-0">
              <ShareIcon style={{ color: 'blue', width: '20px', height: '20px' }} className=" w-4 h-4 mr-1 ml-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[10px] sm:text-[14px] md:text-[14px] mb-0 sm:mb-0 md:mb-2 flex justify-center items-center mt-0 sm:mt-0 md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ResidetialPLOT = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  area = 'N/A',
  // Plotlength = 'N/A',
  // Plotbreath = 'N/A',
  facing = 'N/A',
  Isthisacornerplate = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();
  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2   rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-2 md:ml-0">
        <div>
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        {/* <div className="grid grid-cols-2 gap-4 mr-0 ">
          <div className="flex justify-start items-center">
            <img
              src="https://d34yb5orp91dp6.cloudfront.net/status4.png"
              alt="Status"
              className="w-7 h-7 sm:w-7 sm:h-7 md:w-7 md:h-7  mr-2"
            />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> Plot length</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Plotlength}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/stairs.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Plot breath</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Plotbreath}</p>
            </div>
          </div>
        </div> */}
        <div className="grid grid-cols-2 gap-4">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{area}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>

          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Is this a corner plate ')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Isthisacornerplate}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12  justify-start md:justify-start sm:justify-start items-center sm:items-center mt-4 mb-2">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center sm:items-center md:items-center lg:items-start pt-2 bg-[#BAC1D233] w-full sm:w-full md:w-[100px]  ">
        <div className="flex flex-row sm:flex-row md:flex-col justify-center sm:justify-center md:justify-center items-center sm:items-center md:items-center gap-0 sm:gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 sm:mr-6 ml-6 sm:ml-6  md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[14px] md:text-[14px] flex flex-row md:flex-col items-center sm:items-center md:items-center sm:flex-row ml-0 mr-4 sm:ml-4 sm:mr-4 -mt-2 sm:-mt-2 md:mt-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[10px] md:text-[14px] sm:mb-0 flex flex-row sm:flex-row md:flex-col items-center sm:items-start sm:justify-start md:items-center gap-0 sm:gap-0 md:gap-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full mb-2 mr-0 ">
            <div> Share</div>
            <div className="flex justify-center items-center md:items-start mb-0 sm:mb-0 md:mb-2 mr-4 sm:mr-4 md:mr-0">
              <ShareIcon style={{ color: 'blue', width: '20px', height: '20px' }} className=" w-4 h-4 mr-1 ml-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[10px] sm:text-[14px] md:text-[14px] mb-0 sm:mb-0 md:mb-2 flex justify-center items-center mt-0 sm:mt-0 md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Villa = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  // Totalarea = 'N/A',
  Superarea = 'N/A',
  facing = 'N/A',
  bathroom = 'N/A',
  bedroom = 'N/A',
  Possessionstatus = 'N/A',
  Furnishedstatus = 'N/A',
  Dinningroom = 'N/A',
  // Facingdirection = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]    mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 md:left-6  rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-2 md:ml-0">
        <div>
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-2 mr-0 mt-2">
          {/* <div className="flex justify-start items-center">
            <img
              src="https://d34yb5orp91dp6.cloudfront.net/status4.png"
              alt="Status"
              className="w-7 h-7 sm:w-7 sm:h-7 md:w-7 md:h-7  mr-2"
            />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Total area</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Totalarea}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Superarea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Bathroom')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{bathroom}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Bedroom')} </h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{bedroom}</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-1">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/Possesion.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Possession Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]"> {Possessionstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/furnish.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Furnished Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Furnishedstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Dinning room')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Dinningroom}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12  justify-start md:justify-start sm:justify-start items-center sm:items-center mt-4 mb-2">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center sm:items-center md:items-center lg:items-start pt-2 bg-[#BAC1D233] w-full sm:w-full md:w-[100px]  ">
        <div className="flex flex-row sm:flex-row md:flex-col justify-center sm:justify-center md:justify-center items-center sm:items-center md:items-center gap-0 sm:gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 sm:mr-6 ml-6 sm:ml-6  md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[14px] md:text-[14px] flex flex-row md:flex-col items-center sm:items-center md:items-center sm:flex-row ml-0 mr-4 sm:ml-4 sm:mr-4 -mt-2 sm:-mt-2 md:mt-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[10px] md:text-[14px] sm:mb-0 flex flex-row sm:flex-row md:flex-col items-center sm:items-start sm:justify-start md:items-center gap-0 sm:gap-0 md:gap-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full mb-2 mr-0 ">
            <div> Share</div>
            <div className="flex justify-center items-center md:items-start mb-0 sm:mb-0 md:mb-2 mr-4 sm:mr-4 md:mr-0">
              <ShareIcon style={{ color: 'blue', width: '20px', height: '20px' }} className=" w-4 h-4 mr-1 ml-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[10px] sm:text-[14px] md:text-[14px] mb-0 sm:mb-0 md:mb-2 flex justify-center items-center mt-0 sm:mt-0 md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Builder = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  Supperarea = 'N/A',
  facing = 'N/A',
  Bathrooms = 'N/A',
  Bedroom = 'N/A',
  Possessionstatus = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 md:left-6  rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-2 md:ml-0">
        <div>
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px]  uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Supperarea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Bathroom')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Bathrooms}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth ">{t('Bedroom')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Bedroom}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/Possesion.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Possession Status')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Possessionstatus}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-12  justify-start md:justify-start sm:justify-start items-center sm:items-center mt-4 mb-2">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center sm:items-center md:items-center lg:items-start pt-2 bg-[#BAC1D233] w-full sm:w-full md:w-[100px]  ">
        <div className="flex flex-row sm:flex-row md:flex-col justify-center sm:justify-center md:justify-center items-center sm:items-center md:items-center gap-0 sm:gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 sm:mr-6 ml-6 sm:ml-6  md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[14px] md:text-[14px] flex flex-row md:flex-col items-center sm:items-center md:items-center sm:flex-row ml-0 mr-4 sm:ml-4 sm:mr-4 -mt-2 sm:-mt-2 md:mt-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[10px] md:text-[14px] sm:mb-0 flex flex-row sm:flex-row md:flex-col items-center sm:items-start sm:justify-start md:items-center gap-0 sm:gap-0 md:gap-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full mb-2 mr-0 ">
            <div> Share</div>
            <div className="flex justify-center items-center md:items-start mb-0 sm:mb-0 md:mb-2 mr-4 sm:mr-4 md:mr-0">
              <ShareIcon style={{ color: 'blue', width: '20px', height: '20px' }} className=" w-4 h-4 mr-1 ml-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[10px] sm:text-[14px] md:text-[14px] mb-0 sm:mb-0 md:mb-2 flex justify-center items-center mt-0 sm:mt-0 md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommercialShopCard = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  SuperArea = 'N/A',
  propertyonfloor = 'N/A',
  Possessionstatus = 'N/A',
  Furnishedstatus = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1  md:left-6 text-[12px] sm:text-[12px] md:text-[15px]"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-tight text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/super_area.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Super area')} </h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{SuperArea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/propertyfloor.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Property of the floor')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{propertyonfloor}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/Possesion.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth ">{t('Possession Status')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Possessionstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/furnish.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Furnished status')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Furnishedstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Facing')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full  mr-0 ">
            <div className="flex flex-row md:flex-col md:mt-0 mt-1">
              Share
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Facing"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommercialLand = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  SuperArea = 'N/A',
  // LandZone = 'N/A',
  // Boundarywall = 'N/A',
  Noofopenside = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px] mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6  md:left-6 text-[12px] sm:text-[12px] md:text-[15px] rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700]  leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{SuperArea}</p>
            </div>
          </div>
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">LandZone</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{LandZone}</p>
            </div>
          </div> */}
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/stairs.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth ">Boundary wall</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Boundarywall}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('No of open side')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Noofopenside}</p>
            </div>
          </div>
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Facing')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full  mr-0 ">
            <div className="flex flex-row md:flex-col md:mt-0 mt-1">
              Share
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Facing"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommercialItPark = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  Superarea = 'N/A',
  Totalnooffloor = 'N/A',
  Furnishedstatus = 'N/A',
  // NearbyBusiness = 'N/A',
  Noofopenside = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]   mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 md:left-6 text-[12px] sm:text-[12px] md:text-[15px] rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700]  leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">  {t('Super area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Superarea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth "> {t('Total no of floor')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Totalnooffloor}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/furnish.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Furnished status')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Furnishedstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('No of open side')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Noofopenside}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row items-center sm:items-center md:items-center lg:items-start pt-2 bg-[#BAC1D233] w-full sm:w-full md:w-[100px]  ">
        <div className="flex flex-row sm:flex-row md:flex-col justify-center sm:justify-center md:justify-center items-center sm:items-center md:items-center gap-0 sm:gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 sm:mr-6 ml-6 sm:ml-6  md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[14px] md:text-[14px] flex flex-row md:flex-col items-center sm:items-center md:items-center sm:flex-row ml-0 mr-4 sm:ml-4 sm:mr-4 -mt-2 sm:-mt-2 md:mt-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] sm:text-[10px] md:text-[14px] sm:mb-0 flex flex-row sm:flex-row md:flex-col items-center sm:items-start sm:justify-start md:items-center gap-0 sm:gap-0 md:gap-2 border-b-0 sm:border-b-0 md:border-b-2 border-gray-300 w-full mb-2 mr-0 ">
            <div> Share</div>
            <div className="flex justify-center items-center md:items-start mb-0 sm:mb-0 md:mb-2 mr-4 sm:mr-4 md:mr-0">
              <ShareIcon style={{ color: 'blue', width: '20px', height: '20px' }} className=" w-4 h-4 mr-1 ml-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[10px] sm:text-[14px] md:text-[14px] mb-0 sm:mb-0 md:mb-2 flex justify-center items-center mt-0 sm:mt-0 md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const InderstrialLandCard = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  // Selectunit = 'N/A',
  // Outputunit = 'N/A',
  Totalarea = 'N/A',
  // NearbyBusiness = 'N/A',
  Noofopenside = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 mt-4  md:w-[720px]  h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status ')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Select unit</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Selectunit}</p>
            </div>
          </div> */}
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Output unit</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Outputunit}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/stairs.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth ">  {t('Total area')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Totalarea}</p>
            </div>
          </div>
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Nearby Business</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{NearbyBusiness}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('No of open side ')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Noofopenside}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold  w-[130px] sm:w-full md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold  w-[130px] sm:w-full md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full  mr-0 ">
            <div className="flex flex-row md:flex-col md:mt-0 mt-1">
              Share
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Facing"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Agriculture = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  // Selectunit = 'N/A',
  // Outputunit = 'N/A',
  Totalarea = 'N/A',
  // NearbyBusiness = 'N/A',
  Noofopenside = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',
  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]    mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] md:h-[300px] p-1 object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px]">
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{status}</p>
            </div>
          </div>
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Select unit</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Selectunit}</p>
            </div>
          </div> */}
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/status4.png" alt="Status" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Output unit</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Outputunit}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/stairs.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth ">  {t('Total area')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Totalarea}</p>
            </div>
          </div>
          {/* <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">Nearby Business</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{NearbyBusiness}</p>
            </div>
          </div> */}
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-10 mr-2" />
            <div className="ml-1">
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('No of open side')}</h3>
              <p className="text-[#2E4374] font-[500] font-thirdCustom text-[11px]">{Noofopenside}</p>
            </div>
          </div>
        </div>
        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full  mr-0 ">
            <div className="flex flex-row md:flex-col md:mt-0 mt-1">
              Share
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Facing"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};

export const FormHouse = ({
  property_id,
  title = 'N/A',
  property_address = 'N/A',
  SuperArea = 'N/A',
  propertyonfloor = 'N/A',
  Possessionstatus = 'N/A',
  Furnishedstatus = 'N/A',
  bathroom = 'N/A',
  bedroom = 'N/A',
  facing = 'N/A',
  status = 'N/A',
  enquiredBy = '',
  sharedBy = 0,
  cost = 'N/A',

  imageUrl,
  tag = 'N/A',
  linkToDetails = `/SearchProperty/${property_id}`,
  linkToContact = `/ContactAgent/${property_id}`
}) => {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    if (property_id) {
      window.open(linkToDetails, '_blank');
    } else {
      console.error('Property UUID is undefined.');
    }
  };

  const handleContactClick = () => {
    if (property_id) {
      navigate(linkToContact);
    } else {
      console.error('Property UUID is undefined.');
    }
  };
  console.log('12345678', imageUrl);
  // const formatStatus = (status) => {
  //   return status
  //     .split('_')
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(' ');
  // };

  const bg1 = {
    background: 'rgba(217, 217, 217, 0.80)'
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="card bg-white shadow-lg rounded-lg mx-auto mb-4 hover:scale-[1.01] transition-transform duration-300 md:w-[720px]  mt-4 h-full md:flex">
      <div className="relative w-full md:w-1/2 flex justify-center items-center">
        <LazyLoadImage
          src={imageUrl}
          className="h-[200px] w-full sm:w-full md:w-[500px] p-1 lg:w-[500px] xl:w-[500px] 2xl:w-[500px] 3xl:w-[500px] 4:w-[550px] md:h-[300px] object-cover rounded-md"
        />
        <div
          className="absolute top-10 left-2 sm:left-6 md:left-6 lg:left-6 xl:left-6 2xl:left-6 3xl:left-6 4xl:left-36 rounded-md uppercase shadow-md text-[#2E4374] text-sm font-semibold px-2 py-1"
          style={bg1}
        >
          {tag}
        </div>
      </div>

      <div className="flex flex-col p-4 w-full md:w-1/2 ml-2 sm:ml-5 xs:ml-1 md:ml-0">
        <div className="ml-2">
          <h1 className="font-fifth text-[16px] text-[#2E4374] font-[700] leading-[15px] uppercase text-left">
            {title.length > 10 ? title.slice(0, 10) : title}
          </h1>
          <h2
            className="text-[#1E2858] font-[400] font-sixth leading-[15px] text-[10px] sm:text-[10px] md:text-sm  w-full
           sm:w-1/2 md:w-full "
          >
            {truncateAddress(property_address)}
          </h2>
        </div>

        <div className="grid grid-cols-2 gap-4 mr-0 mt-[10px] ml-2">
          <div className="flex justify-start items-center">
            <img
              src="https://d34yb5orp91dp6.cloudfront.net/propertyfloor.svg"
              alt="Status"
              className="w-7 h-7 mr-2 -mt-1"
            />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Property of the floor')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{propertyonfloor}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/Possesion.svg" alt="Floor" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth">{t('Possession Status')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{Possessionstatus}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/carbon.svg" alt="Area" className="w-7 h-7 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Super Area')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{SuperArea}</p>
            </div>
          </div>
          <div className="flex justify-start items-center ">
            <img src="https://d34yb5orp91dp6.cloudfront.net/bath.svg" alt="Bathroom" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Bathroom')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{bathroom}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/placeholder.svg" alt="Facing" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Facing')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{facing}</p>
            </div>
          </div>
          <div className="flex justify-start items-center">
            <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Bedroom" className="w-6 h-6 mr-2" />
            <div>
              <h3 className="text-[#818EAE] text-[14px] font-[500] font-sixth"> {t('Bedroom')}</h3>
              <p className="text-[#2E4374] capitalize font-[500] font-thirdCustom text-[11px]">{bedroom}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-12 justify-start items-center mt-4 mb-2 md:ml-0 sm:ml-3 xs:ml-2 ml-0">
          <button
            onClick={handleDetailsClick}
            className="text-[#1E2858] font-semibold w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            View Details
          </button>
          <button
            onClick={handleContactClick}
            className="text-[#1E2858] font-semibold  w-[100px] sm:w-[200px] md:w-[130px] h-8  text-[10px] sm:text-[12px] md:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1"
          >
            Contact Agent
          </button>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row items-center pt-2 bg-[#BAC1D233] w-full md:w-[100px]  ">
        <div className="flex flex-row md:flex-col justify-center items-center gap-0 md:gap-4 w-full sm:w-4/5 md:w-full mr-6 ml-6 md:ml-0 md:mr-0 p-3 sm:p-2 md:p-0">
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center ml-0 mr-4 sm:ml-4 md:border-b-2 border-gray-300 w-full md:mb-2 mb-0">
            <div className="flex flex-row md:flex-col">
              Enquired
              <div className="flex justify-start">
                <img
                  src="https://d34yb5orp91dp6.cloudfront.net/fluent.svg"
                  alt="growth"
                  className=" w-6 h-6 md:ml-3 ml-2 -mt-1 md:mt-1 mb-2"
                />
                <div className="mt-1">{enquiredBy}</div>
              </div>
            </div>
          </div>

          <div className="text-[#2E4374] font-sixth font-[600] text-[14px] flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full mr-0 ">
            <div className="flex flex-row md:flex-col">
              Like
              <FavoriteBorderIcon style={{ color: 'red' }} className="w-6 h-6 mr-1 mb-2 ml-2 md:ml-0 -mt-1 md:mt-1" />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  flex flex-row md:flex-col items-center md:border-b-2 border-gray-300 w-full  mr-0 ">
            <div className="flex flex-row md:flex-col md:mt-0 mt-1">
              Share
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/share_icon.svg"
                alt="Facing"
                className="w-6 h-10 mr-2 ml-2 md:ml-0 -mt-2 md:-mt-0"
              />
            </div>
          </div>
          <div className="text-[#2E4374] font-sixth font-[600] text-[14px]  md:mb-2 flex justify-center items-center mb-2 mt-1  md:mt-2">
            {cost}
          </div>
        </div>
      </div>
    </div>
  );
};
