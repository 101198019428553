



import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLocationData, selectCountry, resetLocation } from '../../../../redux/LocationReducer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CountrySelection = () => {
  const { t } = useTranslation(); // Import useTranslation hook
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const isMobileScreen = useMediaQuery('(max-width:1024px)');

  const {
    dataFetched,
    countries,
    selectedCountry: selectedCountryRedux,
    loading
  } = useSelector((state) => state.location);

  useEffect(() => {
    if (!dataFetched) {
      dispatch(fetchLocationData());
    }
  }, [dispatch, dataFetched]);

  useEffect(() => {
    const savedCountry = localStorage.getItem('selectedCountry');
    if (savedCountry && !selectedCountryRedux) {
      dispatch(selectCountry(savedCountry));
    }
  }, [dispatch, selectedCountryRedux]);

  useEffect(() => {
    if (!loading && countries.length > 0 && !selectedCountryRedux && location.pathname !== '/') {
      dispatch(selectCountry(countries[0])); // Select the first country if not on the home page
    }
  }, [loading, countries, selectedCountryRedux, dispatch, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      dispatch(resetLocation());
    }
  }, [location.pathname, dispatch]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCountrySelect = (country) => {
    localStorage.setItem('selectedCountry', country);
    dispatch(selectCountry(country));
    setIsOpen(false);
    navigate('/'); // Redirect to home page
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative inline-block mt-2" ref={dropdownRef}>
      <div
        className="flex items-center gap-1 uppercase hover:underline text-white font-thirdCustom cursor-pointer"
        onClick={toggleDropdown}
      >
        <div className="mt-[3px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.63609 4.63627C2.12139 8.15092 2.12129 13.8493 5.63587 17.3641L9.87998 21.6072C11.0516 22.7776 12.9494 22.7776 14.1208 21.6075L18.3641 17.3641C21.8787 13.8493 21.8786 8.15092 18.3639 4.63627C14.8492 1.12163 9.1508 1.12163 5.63609 4.63627ZM16.9497 6.0505C19.6834 8.78411 19.6834 13.2162 16.9499 15.9499L12.7069 20.1929C12.3168 20.5826 11.6842 20.5826 11.2937 20.1925L7.05002 15.9498C4.31656 13.2162 4.31664 8.78411 7.05029 6.0505C9.78396 3.31688 14.216 3.31688 16.9497 6.0505ZM12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7ZM12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>

        <div className="text-[16px] font-[400] mt-1 capitalize">
          {selectedCountryRedux || t('country')}
        </div>
        <div className="mt-1">{isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
      </div>
      <div className="">
        {isOpen && (
          <div className="absolute right-0 mt-1 bg-white rounded-md shadow-lg w-24 z-10">
            <div className="p-1">
              {countries.map((country) => (
                <div
                  key={country}
                  className="cursor-pointer p-2 ml-3 hover:text-blue-600 text-[16px] font-[400] leading-[20px] flex flex-col capitalize"
                  onClick={() => handleCountrySelect(country)}
                >
                  {country}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountrySelection;



