




import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import global from '../assets/global.svg';

function HandleLanguage() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    const isRTL = i18n.language === 'ckb';
    document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <div className='flex justify-between items-center gap-1'>
      <div>
        <img src={global} className='!w-[19px] !h-[19px] mt-1' alt='country-img' />
      </div>
      <div>
        <select
          onChange={handleLanguageChange}
          defaultValue={i18n.language}
          className="border-none outline-none bg-transparent font-thirdCustom cursor-pointer font-[400] mt-3 px-1 py-4 text-[16px]"
          style={{ color: 'white' }}
        >
          <option className="text-black font-thirdCustom font-[400] mt-2 text-[16px]" value="en">{t('language.english')}</option>
          <option className="text-black font-thirdCustom font-[400] text-[16px]" value="tr">{t('language.turkish')}</option>
          <option className="text-black font-thirdCustom font-[400] text-[16px]" value="ckb">{t('language.sorani')}</option>
        </select>
      </div>
    </div>
  );
}

export default HandleLanguage;

