import React, { useState, useEffect } from 'react';
import { plansData } from './PlanData';
function Plan() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    // Simulating data fetching
    setPlans(plansData);
  }, []);
  return (
    <div className=" mt-5 ">
      {plans.map((plan, index) => (
        <div
          className="border rounded-[20px] border-[#1E2858] shadow-lg m-5 h-[240px] w-[600px]  bg-white"
        >
          <h2 className="text-xl bg-[#C2E7FF] font-[400] rounded-t-[20px]  p-2">{plan.name}</h2>
          <div key={index} className="w-full  flex justify-between p-5 ">
            <ul className="mb-4 list-disc pl-5 ">
              {plan.features.map((feature, index) => (
                <li key={index} className="mb-2 text-[14px] font-[400]  tracking-normal thirdCustom leading-[23px]">
                  {feature}
                </li>
              ))}
            </ul>

            <div className="flex flex-col items-center p-5">
              <span className="text-2xl text-[#1E2858] font-[400] thirdCustom leading-[23px] text-[30px]">{plan.price}</span>
              <button className="bg-[#2E4374] text-white px-4 py-2 mt-5 block w-[120px] thirdCustom leading-[23px] rounded-md">
                {plan.buttonText}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Plan;
