import React from 'react';
import './featured.css';
import Lifestyle from './Lifestyle';
import { useTranslation } from 'react-i18next';
const Featured = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="w-full mt-15 featuredmain">
        <div className="flex flex-col justify-center items-center text-center">
          <h1 className="font-SecondCustom text-white lg:text-5xl md:text-4xl text-4xl mt-40 lg:left-52 md:left-14 lg:w-2/3">
   
            {t('Discover Our Featured Property')}
          </h1>
          <p className="font-thirdCustom text-white  lg:text-base md:text-base text-sm lg:left-52 md:left-32 md:w-2/4 w-3/4">
         
              
            {t('Discover our meticulously designed Properties and unlock a world of elegance and comfort that will surpass your expectation')}
          </p>
        </div>
      </div>

      <div>
        <Lifestyle />
      </div>
    </>
  );
};

export default Featured;
