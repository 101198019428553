import React, { useState, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import apiClient from './apiClient'; // Import the custom Axios instance
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const { i18n } = useTranslation();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Password validation regex
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%*^])[A-Za-z\d@#$%*^]{8,14}$/;

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        'Password must be between 8 and 14 characters, include at least one letter, one digit, and one special character (@#$%*^)';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    setError('');
    const { email, password } = formData;

    try {
      const response = await apiClient.post(
        '/user/login',
        new URLSearchParams({
          grant_type: '',
          username: email,
          password,
          scope: '',
          client_id: '',
          client_secret: ''
        }).toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
          }
        }
      );
      const userData = response.data;
      setUserData(userData);
      console.log('userData', userData);
      localStorage.setItem('userData', JSON.stringify(userData));
      setLoading(false);
      navigate('/dashboard/property');
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else if (error.request) {
        setError('Network Error');
      } else {
        setError('An unexpected error occurred');
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userData) {
      navigate('/dashboard/property');
    }
  }, [userData, navigate]);

  return (
    <div className=" relative ">
      <img
        src={'https://squarebric-media-files.squarebric.com/login7.png'}
        alt="loginimage"
        className={`absolute top-20 right-0 w-[20%] h-auto`}
      />

      <div className="flex justify-center items-center min-h-screen pt-28">
        <form className="bg-white shadow-md  rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmit}>
          <h2 className="text-center   lg:text-[20px] md:text-[20px] sm:text-[16px] xl:text-[20px] font-[400] leading-normal text-[#2E4374] font-SecondCustom capitalize mb-4">
   
            {t('Login')}
          </h2>

          {/* Email Field */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px]  mb-2"
              htmlFor="email"
            >
              {t('Email')}
            </label>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            
                placeholder={t('Email')}
              />
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1"> {t(`${errors.email}`)}</p>}
          </div>

          {/* Password Field */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="password"
            >
              {t('Password')}
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder={t('Enter your Password')}
              />
                <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className={`absolute inset-y-0 ${i18n.language === 'ckb' ? 'left-0' : 'right-0'} px-2 flex items-center`}
      >
        {i18n.language === 'ckb' ? (
          showPassword ? (
            <VisibilityOff className="h-5 w-5 text-gray-600" />
          ) : (
            <Visibility className="h-5 w-5 text-gray-600" />
          )
        ) : (
          showPassword ? (
            <Visibility className="h-5 w-5 text-gray-600" />
          ) : (
            <VisibilityOff className="h-5 w-5 text-gray-600" />
          )
        )}
      </button>
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">  {t(`${errors.password}`)}</p>}
          </div>

          {/* Forgot Password */}
          <div className="flex justify-between">
            <Link
              to="/forgetpassword"
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
            >
              {t('Forgot Password')}?
            </Link>
          </div>

          {/* Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className=" bg-[#2e4374] lg:text-[16px] md:text-[16px] sm:text-[14px] xl:text-[16px] font-[600] leading-normal text-white font-SecondCustom capitalize w-[100%] py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {t('Login')}
            </button>
          </div>

          <div className="text-center mt-2">
            <label className="ml-2 text-[#9A9A9A] relative bottom-1 font-thirdCustom text-[13.94px] font-[400] leading-[119.231%] tracking-[0.139px] lowercase">
              {t('Dont have an account')}?{' '}
              <Link to="/signup" className="text-blue-500">
                {t('Sign Up')}
              </Link>
            </label>
          </div>
        </form>
        {error && <div className="mb-4 text-red-500 text-center">{error}</div>}
      </div>
      <img
        src={'https://squarebric-media-files.squarebric.com/login6.png'}
        alt="loginimage"
        className="absolute bottom-[-56px] left-0  w-[20%] h-auto"
      />
    </div>
  );
};

export default LoginForm;



